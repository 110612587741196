import Component, { attachTo } from "../../shared/component"

import "./comments.scss"

export default class Comments extends Component {
	init() {
		Array.from(this.root.querySelectorAll(`.comments-item`)).forEach(comment => {
			const replyButton = comment.querySelector(`.commentForm-replyButton`)
			const commentForm = comment.querySelector(`.commentForm`)
			if (replyButton) {
				replyButton.addEventListener('click', (event) => {
					event.preventDefault()
					replyButton.classList.add('is-hidden')
					commentForm.classList.remove('is-hidden')
				})
			}
		})
	}
}
  
export const attach = attachTo(`.comments`, root => {
	return new Comments(root, {})
})
