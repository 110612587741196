import Swiper, { Autoplay } from "swiper"
import "swiper/swiper-bundle.css"

import "./stage.scss"

Swiper.use([Autoplay])

const swiper = new Swiper(".stage .swiper-container", {
  autoplay: {
    delay: 6000
  }
})
