import "./commentForm.scss"

Array.from(document.querySelectorAll(".privacy-policy")).forEach((privacyPolicy) => {
  const form = privacyPolicy.closest(".commentForm")
  const submitButton = form.querySelector(".commentForm-submitButton")

  privacyPolicy.addEventListener("change", (e) => {
    const checked = e.target.checked
    if (checked === true) {
      submitButton.removeAttribute("disabled")
    } else {
      submitButton.setAttribute("disabled", "disabled")
    }
  })
})