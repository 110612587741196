import Headroom from "headroom.js"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock"

import Component, { attachTo } from "../../shared/component"

import "./header.scss"

export default class Header extends Component {
  init() {
    this.stickyHeader = new Headroom(this.root, {
      onPin: () => {
        document.documentElement.classList.remove("has-sticky-header")
      },
      onUnpin: () => {
        document.documentElement.classList.add("has-sticky-header")
      }
    })
    this.stickyHeader.init()

    this.mobileMenu = document.querySelector(`.mobileMenu`)
    this.mobileMenuToggles = document.querySelectorAll(`[data-menu]`)

    if (this.mobileMenu && this.mobileMenuToggles) {
      this.mobileMenuToggles.forEach(menuToggle => {
        menuToggle.addEventListener(`click`, event => {
          event.preventDefault()
          if (event.target.dataset.menu === "open") {
            disableBodyScroll(this.mobileMenu)
            this.mobileMenu.classList.add(`is-open`)
          } else {
            enableBodyScroll(this.mobileMenu)
            this.mobileMenu.classList.remove(`is-open`)
          }
        })
      })
    }
  }
}

export const attach = attachTo(`.header`, root => {
  return new Header(root, {})
})
