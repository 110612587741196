import barba from "@barba/core"
import anime from "animejs"
import LazyLoad from "vanilla-lazyload"

import "@assets"
import "@shared"

import "@components/card"
import "@components/comment"
import "@components/commentForm"

import "@modules/header"
import "@modules/intro"
import "@modules/image"
import "@modules/embed"
import "@modules/footer"
import "@modules/stage"
import "@modules/cardGrid"
import "@modules/cardList"
import "@modules/categoryIntro"
import "@modules/pageIntro"
import "@modules/partnerList"
import "@modules/content"
import "@modules/slideshow"

import Header, { attach as attachToHeader } from "./modules/header"
import Comments, { attach as attachToComments } from "./modules/comments"

attachToHeader(document.documentElement)
attachToComments(document.documentElement)
