import { TinyEmitter } from "tiny-emitter"

const resolveSelector = selector => {
  return typeof selector === "string"
    ? document.querySelector(selector)
    : selector
}

class Component extends TinyEmitter {
  constructor(root, ...args) {
    super()
    this.root = root
    this.defaults = args.length > 0 ? args[0] : {}
    this.init(...args)
  }

  init(args) {}

  property(property) {
    if (this.root.dataset) {
      return this.root.dataset[property]
        ? this.root.dataset[property]
        : this.defaults[property]
    }
  }
}

const attachTo = (selector, callback, options = {}) => {
  return (context = document) => {
    const root = resolveSelector(context)
    return Array.from(root.querySelectorAll(selector)).map(root => {
      return callback(root)
    })
  }
}

const destroyAll = instances => {
  instances.forEach(instance => {
    if (
      typeof instance === "object" &&
      typeof instance.destroy === "function"
    ) {
      instance.destroy()
    }
  })
}

export { attachTo, destroyAll }

export default Component
